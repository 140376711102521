<script>
export default {
  name: 'BaseCss',
  data () {
    return {
    }
  }
}
</script>

<template>
  <div class="base">
    powered by 软件创新实验室
    <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">-粤ICP备2023097394号</a>
  </div>
</template>

<style scoped>
.base{
  background: #475669;
  width: 100%;
  height: 65px;
  color: whitesmoke;
  letter-spacing: 2px;
  margin-top: 2%;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
a{
  text-decoration: white;
  color: whitesmoke;
}
</style>
