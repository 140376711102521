import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from "axios";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/study',
    component: () => import('../views/StudyShow.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
