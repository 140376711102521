<script>
export default {
}
</script>

<template>
  <div>
    <div class="box">
      <div class="small_box">
        <img src="peplo/20.png">
        设施齐全,独立自习室,配电，水，空调等
        活动自由，比赛多多
      </div>
      <div class="small_box">
        <img src="peplo/03.jpg">
        经常举办相关科研活动及培训，一起讨论问题，效率up
      </div>
      <div class="small_box">
        <img src="peplo/11.jpg">
        可自带显示器及键盘，更好学习，敲代码
        偷偷内卷，悄悄进步
      </div>
      <div class="small_box">
        <img src="peplo/21.jpg">
        团建活动不少，经常一起吃饭，人员相处融洽，无勾心斗角
      </div>
    </div>
  </div>
</template>

<style scoped>
.box{
  justify-content: center;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  .small_box{
    color: whitesmoke;
    width: 20%;
    height: 390px;
    background: #475669;
    margin-left: 2%;
    margin-bottom: 2%;
    font-size: 17px;
  }
}
img {
  width: 100%;
  height: 85%;
  object-fit: cover;
}
</style>
