<script>
export default {
  name: 'QuesTion',
  data() {
    return {
      Visible: false,
      currentDate: '预计国庆前结束报名，报名结束前3天会在咨询群通知',
      activeName: '1',
      dialogVisible: false,
      url: 'https://qiniu.rzxlyl.cn/e_file/1773703416301998082/15-04-2024-581fa31846e2483db338184224f5846e.png'
    }
  }
}
</script>

<template>
  <div>
    <h1>关于工作室常见的问题<i class="el-icon-question"></i></h1>
    <div class="body">
      <div class="body_left">
        <template>
          <div style="flex-wrap: wrap;display: flex">
            <div class="left">
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    <h1> 1 软件创新工作室主要是干什么的？</h1>
                    <img src="../assets/ico/fire.png">
                  </template>
                  <span>
              软件创新实验室位于教学楼B5-502，是以软件开发为核心的创新型技术工作室。如果你对计算机软件开发很感兴趣，软创将会是你不错的选择。在工作室中，会有志同道合的小伙伴一起学习计算机知识，高年级的学长学姐会指导你，让你在计算机的学习上少走弯路。在相关比赛中，也可以在工作室中寻找合适的小伙伴一同参加比赛！如果能力出众，甚至可以和学长学姐一起，完成项目，丰富简历
            <br>
          </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h1>2 如何进行面试，我该准备什么？</h1>
                    <img src="../assets/ico/fire.png">
                  </template>
                  <span>
              软件创新实验室的招新面向 大一 大二 大三相关专业的同学，招新的方向有大数据，前端，后端，运维方向。不管你是计算机小白，还是已经在计算机道路上越走越远的老生。加入实验室都是一个进步的台阶。面试时，我们将会从学习，生活等各个方面与同学们洽谈，通过面试来判断您适不适合我们工作室。面试将会比较看重同学们的学习态度，及对已有知识的掌握情况。填写报名表后，我们会尽快安排面试，面试采取的是‘你会什么，我们问什么’的提问方式，所以不用特别准备什么，巩固好自己已经掌握的知识即可。面试结束会安排反问环节，我们将会为大一新生解答有关生活，学习，就业等相关疑惑。学长学姐都很友善，希望大家可以勇敢的来参加我们的面试。
            <br>
          </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h1>3 工作室平时有什么活动？</h1>
                    <img src="../assets/ico/fire.png">
                  </template>
                  <span>
                    干饭，干饭活动(一起去校门口吃饭)
                    <br>在工作室沉浸式学习<br>
                    一起参加学科相关竞赛<br>
                    阶段性考核(把握自己当前的学习情况)<br>
                    已经工作的学长学姐回来聚餐，了解就业情况等……
       </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h2>4 加入工作室对我有什么帮助？</h2>
                    <img src="../assets/ico/new.png">
                  </template>
                  <span>
                    在学习上可以得到很大的提升，相关方向的学长学姐带领学习，提出学习建议，少走弯路。与工作室的成员一起合力完成项目开发，丰富项目经验，提升个人能力。参加工作室系列培训，感受计算机行业的协同开发流程。了解更多相关竞赛渠道，与伙伴一同参加。工作室提供自习室学习环境，与小伙伴一起讨论学习，学习道路不孤单。就业的学长学姐会回工作室与工作室成员聊天，传授面试及就业经验。
         </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h2>5 工作室有什么方向，人员结构是怎么样的？</h2>
                    <img src="../assets/ico/new.png">
                  </template>
                  <span>
            工作室目前有后端，前端，大数据方向。工作室没有上下层级关系，只有每个方向相应的负责人。人员结构中有i人,E人,P人,有瓦，有二次元，有抽象，有原神玩家，有Java大王！Java单车！
          </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h2>6 工作室几点开门，如何获得固定位子？</h2>
                  </template>
                  <span>实验楼开门即可前往工作室学习，晚上是不允许留宿工作室的。建议学习的学生最晚10点30也要离开工作室了，不然宿舍没有热水洗澡。工作室分为流动座位和固定座位，新加入工作室的同学统一分配流动位置，可以将物品放置工作室，可以借阅工作室的书籍。后期，经常来工作室学习的同学，可以选择属于自己的固定位置，购买显示器及键盘。</span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h2>7 纯小白，可以加入工作室吗？</h2>
                  </template>
                  <span>
             在工作室中，大一新生可以和学长学姐们一起学习进步，在日常的交流和学习中，去摸索自己喜欢的，未来想走的方向。对于大二的同学，如果你大一知识储备薄弱，学习态度良好，不妨也来我们工作室一起努力。当你看到身边的人都在不断前进时，自己也会更加努力。
         </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h2>8 工作室会有不定时考核吗？ </h2>
                  </template>
                  <span>
         工作室会有不定时的考核，考核加入工作室的同学的学习情况，一般为一学期的学期末或开学。工作室的资源是有限的，加入了工作室却懈怠学习的同学，将会在考核中被淘汰。如果在工作室中一直认真的完成自己的学习任务，那考核根本不是什么困难。比你更早进入工作室的同学，都是经过一轮轮考核后留下来的，比较优秀的同学。
         </span>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <h2>9 本次招新要招多少人？ </h2>
                  </template>
                  <span>
         招取人数不确定，对大一的要求会低一点，比较看重学习态度。最后录取人数视同学们的具体情况而定。本次招新面向大一，大二，大三的同学，大二和大三同学的要求会相对提高。工作室目前最欠缺的岗位为前端开发！
         </span>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </template>
      </div>
      <div class="body_right">
        <el-row style="width: 80%;margin-left: 15%">
          <el-col :span="8" :key="o">
            <el-card :body-style="{ padding: '0px' }">
              <img src="../assets/cat.gif"
                   class="image">
              <div style="padding: 14px;">
                <span>努力敲码的猫猫</span>
                <div class="bottom clearfix">
                  <time class="time">{{ currentDate }}</time>
                  <el-button type="text" class="button" @click="Visible=true">和猫猫一起努力，加入咨询群</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="扫码加入咨询群" :visible.sync="Visible">
      加入咨询群了解更多……
      <img src="../assets/QQ.png" style="width: 50%;height: 50%">
    </el-dialog>
  </div>
</template>

<style scoped>
.body {
  display: flex;
  justify-content: center;
  align-content: center;
}

.body .body_left {
  background: #98a8be;
  width: 70%;
  margin-left: 5%;

  a {
    color: #bf8989;
    text-decoration: none;
  }

  a:hover {
    color: #7597de;
  }

  i:hover {
    color: #7597de;
  }

  i {
    color: #bf8989;
    text-decoration: none;
  }

  .left {
    width: 100%;
    height: auto;
    letter-spacing: 4px;

    span {
      font-size: 18px;
    }
  }

  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    display: block;
    height: 300px;
    margin-left: 9.5%;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }
}

.body .body_right {
  width: 35%;

  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }
}
</style>
