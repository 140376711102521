<script>
import TitleCss from '@/components/TitleCss.vue'
import DisplayTitle from '@/components/DisplayTitle.vue'
import Questions from '@/views/Questions.vue'
import ActionShow from '@/views/ActionShow.vue'
import BaseCss from '@/components/Base.vue'

export default {
  components: {
    BaseCss,
    ActionShow,
    DisplayTitle,
    TitleCss,
    Questions
  },
  mounted () {
    // const WIDTH = 705
    const WIDTH = 1920
    const mobileAdapter = () => {
      const scale = screen.width / WIDTH

      const content = `width=${WIDTH}, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}`

      let meta = document.querySelector('meta[name=viewport]')

      if (!meta) {
        meta = document.createElement('meta')

        meta.setAttribute('name', 'viewport')

        document.head.appendChild(meta)
      }
      meta.setAttribute('content', content)
    }
    mobileAdapter()
    window.onorientationchange = mobileAdapter
  }
}
</script>
<template>
  <div id="app">
    <title-css></title-css>
    <DisplayTitle></DisplayTitle>
    <div class="demo">
      <h2>对软件开发还不太了解……</h2>
        <router-link to="/study" style="text-decoration: white">
          <p> 软件硬件？什么是前端？什么是后端？我该怎么学？ 了解更多 >>>></p>
        </router-link>
    </div>
    <ActionShow></ActionShow>
    <Questions></Questions>
    <BaseCss></BaseCss>
  </div>
</template>

<style>
.demo {
  margin-left: -70%;
  font: 500 20px '华文楷体';
  color: black;
  letter-spacing: 1px;
  margin-top: 20px;

  p {
    margin-top: 7px;
    font-size: 1em;
    margin-left: 20%;
    color: #8e9393;
    text-decoration: none;
    margin-bottom: 2%;
  }

  p:hover {
    color: #51c3c6;
    cursor: pointer;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
