import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Carousel  ,Form,Checkbox,Radio,Upload,Select, ButtonGroup, RadioButton,Tooltip,RadioGroup,
  FormItem,Option,Input, CarouselItem, Steps, Step, Link, Statistic, Avatar, Card, Tabs, TabPane, Tree, Dialog, Button, Image, Col, Row, MessageBox, Message, Collapse, CollapseItem, Badge, Tag } from 'element-ui'
import store from './store'
import $ from 'jquery'
import 'amfe-flexible/index.js'
import 'element-ui/lib/theme-chalk/index.css'
import "animate.css"
import 'wowjs/css/libs/animate.css'
Vue.use(RadioGroup)
Vue.use(Carousel)
Vue.use(Tooltip)
Vue.use(RadioButton)
Vue.use(CarouselItem)
Vue.use(Radio)
Vue.use(Option)
Vue.use(Statistic)
Vue.use(Avatar)
Vue.use(Card)
Vue.use(TabPane)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Image)
Vue.use(Collapse)
Vue.use(Row)
Vue.use(CollapseItem)
Vue.use(Badge)

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;
window.document.title = '软件创新实验室招新宣传网'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  $,
  render: h => h(App)
}).$mount('#app')
