<script>
import CarC from '@/components/CarCss.vue'
import Join from '@/components/Join.vue'

export default {
  components: {
    Join,
    CarC
  },
  data() {
    return {
      height: '475px',
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'peplo/14.jpg',
        'peplo/15.jpg',
        'peplo/16.jpg',
        'peplo/17.jpg'
      ],
      picList: [
        {
          url: 'peplo/01.jpg'
        },
        {
          url: 'peplo/02.jpg'
        },
        {
          url: 'peplo/09.jpg'
        },
        {
          url: 'peplo/12.jpg'
        }
      ]
    }
  }
}
</script>

<template>
  <div>
    <div class="body">
      <div class="left_body">
        <el-carousel indicator-position="outside" :height="this.height" class="Xbox">
          <el-carousel-item v-for="urlPic in picList" :key="urlPic.url">
            <img :src=urlPic.url alt="加载失败">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right_body">
        <div class="box" v-for="(url,index) in srcList" :key="index+5">
          <div class="demo-image__preview">
            <el-image
              :src="url"
              :preview-src-list="srcList">
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- 空格白+加入我们 -->
    <div class="di">
      <div class="text">
        <p style="font-size: 27px;margin-top: 30px">更适合 计科 / 软件工程/ 大数据专业 / 热爱软件开发的……</p>
        <p style="font-size: 24px">点燃创新的火花，构筑数字化的未来</p>
        <p style="font-size: 24px">充满活力，激发创意，让我们一起共同创造非凡</p>
        <p style="font-size: 32px">软件创新实验室，欢迎您的加入</p>
        <div>
          <p>
            <Join></Join>
          </p>
        </div>
      </div>
      <div class="jion">
        <CarC></CarC>
      </div>
    </div>
  </div>
</template>

<style scoped>
.di {
  width: auto;
  height: 320px;
  margin-top: 2%;
  display: flex;
  justify-content: center;
  align-content: center;

  .text {
    background: #475669;
    width: 60%;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
      width: 100%;
      height: 2%;
    }
  }

  .jion {
    background: #99a9bf;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.body {
  display: flex;

  .left_body {
    width: 65%;
    height: 410px;
    background: #475669;
    .Xbox{
     margin-top: 4.5%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-left: 20px;
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 310px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background: #475669;
    }

    .el-carousel__item:nth-child(2n+1) {
      background: #475669;
    }
  }

  @media only screen and (max-width: 1919px) {
    .left_body {
      width: 65%;
      height: 410px;
      background: #475669;
      .Xbox{
        margin-top: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-left: 20px;
      }

      .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 310px;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background: #475669;
      }

      .el-carousel__item:nth-child(2n+1) {
        background: #475669;
      }
    }
  }

  .right_body {
    width: 35%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    height: 410px;
    background: #475669;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .box {
      width: 48%;
      padding-left: 5px;
      padding-bottom: 0;
    }
  }
}
</style>
