import { render, staticRenderFns } from "./DisplayTitle.vue?vue&type=template&id=9a777ccc&scoped=true"
import script from "./DisplayTitle.vue?vue&type=script&lang=js"
export * from "./DisplayTitle.vue?vue&type=script&lang=js"
import style0 from "./DisplayTitle.vue?vue&type=style&index=0&id=9a777ccc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a777ccc",
  null
  
)

export default component.exports