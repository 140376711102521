<script>
export default {
  data () {
    return {
      Visible1: false,
      Visible2: false,
      Visible3:false
    }
  }
}
</script>

<template>
  <div>
    <div class="main">
      <div class="routCss">
        <ul id="nav">
          <li>
            <router-link to="/">返回顶部</router-link>
          </li>
          <li>
            <router-link to="/study">了解更多</router-link>
          </li>
          <li @click="Visible1 = true">
            <router-link to="#">加入咨询群</router-link>
          </li>
          <li @click="Visible3 = true">
            <router-link to="#">加入我们</router-link>
          </li>
          <li @click="Visible2 = true">
            <router-link to="#>">投稿/建议</router-link>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog title="扫码加入咨询群" :visible.sync="Visible1" >
      加入咨询群了解更多……
      <img src="../assets/QQ.png" style="width: 50%;height: 50%">
    </el-dialog>

    <el-dialog title="投稿 / 建议" :visible.sync="Visible2">
      暂未开发请稍后……
      <img src="../assets/pig.gif" style="width: 50%;height: 52%">
    </el-dialog>

    <el-dialog title="扫码填写报名表" :visible.sync="Visible3">
      填写后会陆续短信通知面试
      <img src="../assets/jion.jpg" style="width: 55%;height: 55%">
    </el-dialog>
  </div>
</template>

<style scoped>
.main {
  width: 100%;
  background-color: #f9f9f9;
  align-items: center;
  display: flex;
  justify-content: center;
}

.routCss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.routCss ul li a:hover,
.routCss ul li a.active {
  border-radius: 64px;
  background: rgba(167, 209, 241, 0.91);
}

#nav {
  position: relative;
  border: none;
  display: flex;
  list-style: none;
  height: 34px;
  background: #f5f5f5;
//box-shadow: 20px 30px 30px rgba(91, 89, 89, 0.47);
}

#nav li a {
  position: relative;
  padding: 1px 20px;
  font: 500 25px '华文楷体';
  border: none;
  outline: none;
  color: rgb(93, 132, 194);
  display: inline-block;
  text-decoration: none;
  z-index: 3;
}

</style>
