import { render, staticRenderFns } from "./CarCss.vue?vue&type=template&id=546b2641&scoped=true"
import script from "./CarCss.vue?vue&type=script&lang=js"
export * from "./CarCss.vue?vue&type=script&lang=js"
import style0 from "./CarCss.vue?vue&type=style&index=0&id=546b2641&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546b2641",
  null
  
)

export default component.exports